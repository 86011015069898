/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'angular-calendar/css/angular-calendar.css';
@import 'variables';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  --kreative-nav-menu-icon: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  --kreative-nav-menu-border-radius: 0;
}

// Custom Camlyst Button style
.mat-action {
  background-color: $blue-100;
  color: #fff;
}

// Navbar Styles
.top-nav-collapse {
  background-color: $blue-100;
}

.navbar-landing {
  @media (max-width: $screen-m) {
    background-color: $blue-100;
  }
}

mdb-navbar .navbar.navbar-1 .navbar-toggler-icon {
  background-image: var(--kreative-nav-menu-icon);
  border-radius: var(--kreative-nav-menu-border-radius);
  width: $x-large;
  height: $x-large;
}

// Non landing pages
.navbar-contrast {
  background-color: $blue-100;
}

.app-pages {
  padding: $space-32 $space-16;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
