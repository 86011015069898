// Spaces
$space-00: 0px;
$space-02: 2px;
$space-04: 4px;
$space-08: 8px;
$space-16: 16px;
$space-24: 24px;
$space-32: 32px;
$space-48: 48px;
$space-64: 64px;

// Borders
$border-thin: 1px;
$border-regular: 3px;
$border-thick: 5px;

// Font sizes
$small: 8px;
$regular: 18px;
$large: 24px;
$x-large: 32px;
$xx-large: 48px;
$xxx-large: 64px;

// Special
$page-margin-top: 112px;

// Weights
$thin-100: 100;
$regular-300: 300;
$regular-400: 400;
$bold-500: 500;
$bold-600: 600;

// Colors
$primary: #4285F4;
$black: #161616;
$white: #FFFFFF;
$neutral-100: #eeeeee;
$neutral-150: #e3e0e0;
$neutral-200: #b4b2bd;
$neutral-300: #717078;
$neutral-400: #2e3131;
$red-100: #B33A3A;
$blue-10: #f7fbfe;
$blue-20: #0C80D0;
$blue-50: #24649A;
$blue-100: #0A9BD7;
$blue-200: #1f5784;
$blue-300: #277FC6;
$yellow-100: #FFD740;
$tint: rgba(0, 0, 0, 0.2);

// Screen sizes
$screen-xs: 579px;
$screen-s: 768px;
$screen-m: 992px;
$screen-lg: 1200px;
$screen-xl: 1800px;
